<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card border">
          <div class="psh-header p-1 mb-0 d-flex align-items-center border-bottom">
            <div class="mr-1 border-0">
              <p-icon name="feather-command" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Resource Management
              </h4>
              <p class="card-text mb-0 text-muted">
                Create resources for role management and permissions.
              </p>
            </div>
          </div>
          <div class="card-body px-half">
            <div class="p-half rounded">
            <h5 class="p-1 bg-light-primary">
Create Resource
</h5>
            <div class="d-flex flex-wrap py-2 px-1">
              <div class="col-12 col-sm-8 col-md-5 col-lg-4 px-half">
                <dx-util-text-box v-model="resource.subjectName" :show-clear-button="true" label="Subject Name" placeholder="format: resource_menuheader_menu_resource_name" class="" label-mode="static" />
              </div>
              <div class="col-12 col-sm-4 col-md-3 col-lg-2 px-half">
                <dx-util-text-box v-model="resource.name" label="Name" :show-clear-button="true" label-mode="static" />
              </div>
              <div class="col-12 col-sm-5 col-md-4 col-lg-3 px-half">
                <dx-util-text-box v-model="resource.description" label="Description" :show-clear-button="true" label-mode="static" />
              </div>
              <div class="col-12 col-sm-5 col-md-2 px-half">
                <dx-util-select-box v-model="resource.resourceScope" :data-source="resourceScopeList" label="Scope" value-expr="" :show-clear-button="true" label-mode="static" />
              </div>
              <div class="col-12 col-sm-2 col-md-1 my-half">
                <dx-util-button text="Create" type="default" class="text-white" :on-click="onCreateResource" style="min-width:70px" />
              </div>
            </div>
          </div>
          <div class="divider my-1">
            <div class="divider-text text-primary">
              ◀▶
            </div>
          </div>
          <div class="p-half rounded">
            <h5 class="p-1 text-success bg-light-success">
Grant Resource
</h5>
            <div class="row py-2 px-1">
              <div class="col-12 col-lg-10 col-xl-6 pt-half">
                <dx-util-select-box v-model="selectedResource" :data-source="resourceList" :search-enabled="true" display-expr="text" value-expr="value" class="" label-mode="static" />
              </div>
              <div class="col-12 col-md-4 col-lg-2 pt-half">
                <dx-util-select-box v-model="selectedCompanyType" :data-source="companyTypeList" display-expr="text" value-expr="value" class="" label-mode="static" />
              </div>
              <div class="col-12 col-md-7 col-xl-4 pt-half">
                <dx-util-check-box v-model="roleResourceAction.read"
                  text="Read" class="mr-1"
                />
                <dx-util-check-box v-model="roleResourceAction.create"
                  text="Create" class="mr-1"
                />
                <dx-util-check-box v-model="roleResourceAction.write"
                  text="Write" class="mr-1"
                />
                <dx-util-check-box v-model="roleResourceAction.delete"
                  text="Delete" class="mr-1"
                />
                <dx-util-button :text="buttonCaption" type="default" class="text-white ml-1" :on-click="onGrantResource" style="min-width:70px" />
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Notify } from '@/@robustshell/utils'
import userResourceService from '@/http/requests/system/userResourceService'
import RoleResourceAction from '@/http/models/system/user-management/roleResourceAction'

export default {
  data() {
    return {
      buttonCaption: 'Grant',
      roleResourceAction: new RoleResourceAction(),
      resource: {
        subjectName: '',
        resourceScope: '',
        name: '',
        description: '',
      },
      resourceList: [],
      resourceScopeList: ['ALL', 'WAREHOUSE', 'CUSTOMER'],
      selectedResource: 'type',
      companyTypeList: [
        { text: 'Customer', value: 'customer' },
        { text: 'Warehouse', value: 'warehouse' },
        { text: 'franchise', value: 'franchise' },
      ],
      selectedCompanyType: 'type',
    }
  },
  watch: {
    selectedCompanyType() {
      this.fetchCompanyGetRoleResourceAction()
    },
    selectedResource() {
      this.fetchCompanyGetRoleResourceAction()
    },
    roleResourceAction: {
      // eslint-disable-next-line func-names
      handler: function () {
        if (this.roleResourceAction && this.roleResourceAction.id && this.selectedResource !== 'type' && this.selectedCompanyType !== 'type' && this.roleResourceAction.read === false && this.roleResourceAction.create === false && this.roleResourceAction.write === false && this.roleResourceAction.delete === false) {
          this.buttonCaption = 'Revoke'
        } else {
          this.buttonCaption = 'Grant'
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchAllResources()
  },
  methods: {
    onCreateResource() {
      if (this.resource.subjectName === '' || this.resource.subjectName.length < 10) {
        Notify.warning('Please enter a resource name, at least 10 characters')
        return
      }
      userResourceService.createResource(this.resource).finally(() => {
        this.fetchAllResources()
        this.onCancelCreateResource()
      })
    },
    onCancelCreateResource() {
      this.resource = {
        subjectName: '',
        name: '',
        description: '',
      }
    },
    fetchCompanyGetRoleResourceAction() {
      if (this.selectedResource === 'type') {
        return
      }
      if (this.selectedCompanyType === 'type') {
        return
      }

      this.roleResourceAction = new RoleResourceAction()
      const self = this
      userResourceService.getTemplateRoleResourceActionByCompanyTypeAndResourceId(this.selectedCompanyType, this.selectedResource).then(response => {
        self.roleResourceAction = response.data
      })
    },
    fetchAllResources() {
      userResourceService.fetchAllResources().then(result => {
        const { data } = result
        // this.resourceList.push({ text: 'Resource type', value: 'type' })
        data.forEach(resource => {
          this.resourceList.push({ text: `${resource.name} (${resource.subjectName})`, value: resource.id })
        })
      })
    },
    onGrantResource() {
      if (this.selectedResource === 'type') {
        Notify.error('Please select a resource')
        return
      }
      if (this.selectedCompanyType === 'type') {
        Notify.error('Please select a company type')
        return
      }

      if (this.buttonCaption === 'Grant' && this.roleResourceAction.read === false && this.roleResourceAction.create === false && this.roleResourceAction.write === false && this.roleResourceAction.delete === false) {
        Notify.error('At least one of the actions must be selected.')
        return
      }

      userResourceService.grantResourceByCompanyType(this.selectedResource, this.selectedCompanyType, this.roleResourceAction).finally(() => {
        this.fetchCompanyGetRoleResourceAction()
      })
    },
  },
}
</script>
