export class RoleResourceAction {
  id = null

  resource = null

  role = null

  read = false

  create = false

  write = false

  delete = false
}
export default RoleResourceAction
